@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-supernova-10: #fffae3;
    --color-supernova-50: #fff4bd;
    --color-supernova-100: #ffed97;
    --color-supernova-200: #ffe671;
    --color-supernova-300: #ffe04a;
    --color-supernova-400: #ffd924;
    --color-supernova-500: #FDD100;
    --color-supernova-600: #d7b100;
    --color-supernova-700: #b09200;
    --color-supernova-800: #8a7200;
    --color-supernova-900: #645300;

    --color-mirage-10: #908C8C;
    --color-mirage-50: #7D7979;
    --color-mirage-400: #2F2E2E;
    --color-mirage-500: #1C1B1B;
    --color-mirage-600: #090808;
    --color-mirage-700: #010101;

    --color-thunder: #2D2E34;

    --color-lavender-blue: #868CFF;

    --color-zircon: #F4F7FE;

    --color-primary: #FDD100;
    --color-secondary: #1C1B1B;
    --color-accent: #FF3A44;
    --color-neutral: #FFA02D;
    --color-base-100: #FFFFFF;
    --color-info: #1C1917;
    --color-success: #36D399;
    --color-warning: #FBBD23;
    --color-error: #E6462E;

    --toastify-color-info: #fdd100;
}

@layer base {
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    html {
        font-family: 'DM Sans', sans-serif !important;
        font-feature-settings: 'kern' !important;
        -webkit-font-smoothing: antialiased;
        letter-spacing: -0.5px;
    }

    body {
        color: #010101;
    }

    .font-outline-2 {
        -webkit-text-stroke: 2px black;
        paint-order: stroke fill;
    }

    .font-outline-3 {
        -webkit-text-stroke: 3px black;
        paint-order: stroke fill;
    }

    .font-outline-4 {
        -webkit-text-stroke: 4px black;
        paint-order: stroke fill;
    }
}

.select,
.input,
.textarea {
    @apply !outline-none;
}

