.tabs {
    border-radius: 6px;
    overflow: hidden;
}

.tab-buttons {
    display: flex;
}

.tab-buttons button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tab-buttons button:hover {
    background-color: #ddd;
    border-radius: 6px;
}

.tab-buttons button.active {
    background-color: #f2a041;
    border-radius: 6px;
}

.tab-content {
    padding: 20px;
}
